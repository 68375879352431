<script setup lang='ts'>
import { ref } from 'vue'
import AppMenuItem from './AppMenuItem.vue'
import { useNavigationMenu } from '~/composables/navigation'
const { layoutConfig } = useLayout()

const { navigationMenu } = useNavigationMenu()
const model = navigationMenu()
const infoApp = appConfig.infoapp
onMounted(() => {
	layoutConfig.menuMode.value = 'overlay'
})
</script>

<template>
  <client-only>
    <ul class="layout-menu">
      <template v-for="(item, i) in model" :key="item">
        <app-menu-item v-if="!item.separator" :item="item" :index="i" />
        <li v-if="item.separator" class="menu-separator" />
      </template>
    </ul>
    <div class="text-3 text-gray-500 " style="bottom: 0; position: absolute; width: 100%; text-align: left;">
      ver. {{ `${infoApp.version} del
				${infoApp.datebuild}` }}
    </div>
  </client-only>
</template>

<style lang="scss" scoped></style>
